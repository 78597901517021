<ng-container *ngIf="project">
  <section>
    <div class="flex flex-mobile space-between align-center m-b-gutter">
      <div class="left-part">
        <h1 class="title is-2 is-light">
          <i class="icon icon-grid m-r-sm is-size-3 is-hidden-touch"></i>
          {{ project.label }}
          <span
            class="is-regular m-r-md has-text-grey-dark is-size-4"
            *ngIf="project.department"
          >
            - {{ project.department.name }}
          </span>

          <a
            *ngIf="project.customer?.frameworkContract"
            class="button is-link is-small is-hidden-touch"
            [href]="storagePath + project.customer?.frameworkContract"
            download
            target="_blank"
            ><i class="icon icon-download"></i><span>Contrat cadre</span></a
          >
        </h1>
      </div>
      <div class="right-part flex flex-mobile flex-wrap flex-end button-group">
        <a
          (click)="abandon(!project.isAbandoned)"
          *ngIf="isUserAllowedToAccess('abandonProject')"
          class="button is-warning is-hidden-touch"
        >
          <ng-container *ngIf="!project.isAbandoned">
            <span class="is-hidden-until-fullhd">Abandonner</span>
            <i class="is-hidden-fullhd icon icon-trash-2 ml-0 px-0 mx-1"></i>
          </ng-container>
          <ng-container *ngIf="project.isAbandoned">
            <span class="is-hidden-until-fullhd">Ré-activer</span>
            <i
              class="is-hidden-fullhd icon icon-check-circle ml-0 px-0 mx-1"
            ></i>
          </ng-container>
        </a>
        <ng-container *ngIf="budgets && budgets.length">
          <div
            class="dropdown m-l-gutter"
            [ngClass]="{ 'is-active': showDownloadProposal }"
          >
            <a
              (click)="showDownloadProposal = !showDownloadProposal"
              class="button is-info is-hidden-touch"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
            >
              <span class="is-hidden-until-fullhd">Télécharger</span>
              <i class="is-hidden-fullhd icon icon-download ml-0 px-0 mx-1"></i>
              <i class="icon m-l-sm icon-chevron-down"></i>
            </a>

            <a
              (click)="showDownloadProposal = !showDownloadProposal"
              class="button is-primary is-rounded is-hidden-desktop"
            >
              <div class="icon icon-download"></div>
            </a>

            <div
              class="dropdown-menu drop--down"
              *ngIf="showDownloadProposal"
              id="donwload-dropdown"
            >
              <div class="dropdown-content">
                <ng-container *ngFor="let budget of budgets">
                  <a
                    class="dropdown-item"
                    (click)="downloadProposal(budget.id, 'fr')"
                  >
                    Proposition de {{ budget.name }} (FR)
                  </a>
                  <a
                    class="dropdown-item"
                    (click)="downloadProposal(budget.id, 'en')"
                  >
                    Proposition de {{ budget.name }} (EN)
                  </a>
                  <a
                    class="dropdown-item"
                    (click)="downloadDetailedExport(budget.id)"
                  >
                    {{ budget.name }} détaillé
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Validate project -->
        <ng-container
          *ngIf="project.status === ProjectStatus.PendingValidation"
        >
          <a
            class="button is-info is-hidden-touch m-l-gutter"
            *abcHasPermission="'validateProjects'"
            (click)="showValidationModal = true"
            >Valider</a
          >
        </ng-container>

        <!-- Change project status -->
        <a
          class="button is-success is-hidden-touch m-l-gutter"
          *ngIf="isUserAllowedToAccess('manageClassicStatus')"
          (click)="showStatusEditModal = true"
          >Statut</a
        >
        <a
          [routerLink]="['/missions', project.id, 'edit']"
          class="button is-primary is-hidden-until-fullhd m-l-gutter"
          *ngIf="isUserAllowedToAccess('editProject')"
          >Modifier
        </a>
        <a
          [routerLink]="['/missions', project.id, 'edit']"
          class="button is-primary is-rounded is-hidden-fullhd m-l-colgap"
        >
          <div class="icon icon-edit-2"></div>
        </a>
      </div>
    </div>
    <div class="columns flex flex-wrap flex-touch flex-wrap-touch">
      <!-- Basic information -->
      <div class="column is-12 is-12-mobile is-9-widescreen p-x-0-mobile">
        <div class="full-height flex flex-wrap full-width">
          <div
            class="card p-a-0 p-b-colgap m-b-gutter full-width"
            *ngIf="project.customer"
          >
            <!-- 1st line-->
            <div class="columns p-x-gutter p-t-colgap is-multiline">
              <div class="column is-3">
                <p class="title--small">Informations client</p>
                <p>
                  <a
                    [routerLink]="['/clients', project.customer.id]"
                    class="has-text-black"
                  >
                    {{ project.customer.name }}
                  </a>
                </p>
                <p>
                  {{ project.customer.address }}
                </p>
              </div>
              <div class="column is-3">
                <p class="title--small">Facturation</p>
                <p>
                  {{ project.customer.billingAddress }}
                </p>
              </div>
              <div class="column is-2">
                <p class="title--small">Responsable mission</p>
                <p *ngIf="project.referent">
                  {{ project.referent.name }}
                </p>
              </div>
              <div class="column is-2">
                <p class="title--small">Email de facturation</p>
                <p *ngIf="project.billingEmail">
                  <a
                    href="mailto:{{ project.billingEmail }}"
                    target="_blank"
                    class="has-text-black"
                  >
                    {{ project.billingEmail }}
                  </a>
                </p>
              </div>

              <div class="column is-12-mobile is-3-tablet is-2-desktop">
                <p class="title--small">Ref. bon de commande</p>
                <p>
                  {{ project.purchaseOrder }}
                </p>
              </div>
            </div>
          </div>
          <div class="card p-a-0 p-b-colgap full-width">
            <!-- 2nd line-->
            <div class="columns p-x-gutter p-t-colgap">
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Date de création</p>
                <p>
                  {{ project.creationDate | date }}
                </p>
              </div>

              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Nom de la mission</p>
                <p>
                  {{ project.name }}
                </p>
              </div>
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Référence</p>
                <p>
                  {{ project.reference }}
                </p>
              </div>
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small row-mobile">Statut</p>
                <abc-progress-bar-yield
                  [status]="project.status"
                ></abc-progress-bar-yield>
              </div>
            </div>
            <div class="columns p-x-gutter p-t-colgap">
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Probabilité de signature</p>
                <p>{{ project.closeProbability / 100 | percent : '1.0-2' }}</p>
              </div>
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Origine</p>
                <p>
                  {{ project.origin }}
                  <ng-container *ngIf="project.originName"
                    >- {{ project.originName }}</ng-container
                  >
                  <ng-container *ngIf="project.sourcingPartner"
                    >- {{ project.sourcingPartner.name }}</ng-container
                  >
                </p>
              </div>
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Practice</p>
                <p *ngIf="project.department">
                  {{ project.department.name }}
                </p>
              </div>
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Taux de prime</p>
                <p *ngIf="project.bonusRate">
                  {{ project.bonusRate / 100 | percent : '1.0-2' }}
                </p>
              </div>
            </div>
            <hr class="m-y-0" />
            <div class="columns p-x-gutter p-b-gutter p-t-colgap">
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Date de début planifiée</p>
                <p>
                  {{ project.estimatedStartDate | date }}
                </p>
              </div>
              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Date de fin planifiée</p>
                <p>
                  {{ project.estimatedEndDate | date }}
                </p>
              </div>

              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Bénéficiaire(s) de la prime</p>
                <p>
                  <a
                    class="project-user"
                    [routerLink]="['/collaborateurs', bonus.beneficiary.id]"
                    *ngFor="let bonus of project.bonuses"
                  >
                    <app-image
                      [path]="bonus.beneficiary.image"
                      [title]="bonus.beneficiary.name"
                      [alt]="bonus.beneficiary.name"
                      className="avatar-sm"
                    >
                    </app-image>
                    <div>
                      <span class="has-text-black">
                        {{ bonus.beneficiary.name }}
                        <ng-container *ngIf="bonus.percentage < 1"
                          >({{ bonus.percentage | percent }})</ng-container
                        >
                      </span>
                    </div>
                  </a>
                  <span class="has-text-black" *ngIf="!project.bonuses.length"
                    >-</span
                  >
                </p>
              </div>

              <div class="column is-12-mobile is-3-tablet">
                <p class="title--small">Année de tarification</p>
                <p>
                  {{ project.pricingYear }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-12 is-12-mobile is-3-widescreen p-x-0-mobile">
        <div class="card full-height">
          <h3 class="title--small has-text-black">Objet de la mission</h3>
          <a
            *ngIf="project.comments"
            class="p-l-md is-size-8"
            [routerLink]="['/missions', project.id, 'edit']"
            ><i class="icon icon-edit-2 has-text-grey-dark m-r-xs"></i>
            Modifier
          </a>

          <ng-container *ngIf="project.comments">
            <div class="comment">
              <p>{{ project.comments }}</p>
            </div>
          </ng-container>
          <div *ngIf="!project.comments">
            <p class="p-t-colgap">Cette mission n'a pas encore d'objet.</p>
            <p class="p-b-colgap" *ngIf="isUserAllowedToAccess('editProject')">
              Vous pouvez spécifier l'objet de la mission via sa page de
              modification.
            </p>
            <a
              class="button full-width is-primary"
              [routerLink]="['/missions', project.id, 'edit']"
              *ngIf="isUserAllowedToAccess('editProject')"
            >
              Ajouter un objet</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="card p-a-0 p-b-colgap m-y-gutter">
      <div class="columns">
        <div class="column p-b-0">
          <div class="tabs is-centered">
            <ul>
              <li
                [ngClass]="{ 'is-active': selectedTab === 'budget' }"
                (click)="selectedTab = 'budget'"
                *ngIf="isUserAllowedToAccess('seeBudget')"
              >
                <a>Budget</a>
              </li>
              <li
                [ngClass]="{ 'is-active': selectedTab === 'bookedWork' }"
                (click)="selectedTab = 'bookedWork'"
                *ngIf="isUserAllowedToAccess('seeBookedWorks')"
              >
                <a>Réservations</a>
              </li>
              <li
                [ngClass]="{ 'is-active': selectedTab === 'followUp' }"
                (click)="selectedTab = 'followUp'"
                *ngIf="isUserAllowedToAccess('seeFollowUp')"
              >
                <a>Suivi</a>
              </li>

              <ng-container *ngIf="!project.notBillable">
                <li
                  [ngClass]="{ 'is-active': selectedTab === 'accounting' }"
                  (click)="selectedTab = 'accounting'"
                  *ngIf="isUserAllowedToAccess('seeAccounting')"
                >
                  <a>Comptabilité</a>
                </li>
              </ng-container>
              <li
                [ngClass]="{ 'is-active': selectedTab === 'documents' }"
                (click)="selectedTab = 'documents'"
              >
                <a>Documents</a>
              </li>
              <li
                [ngClass]="{ 'is-active': selectedTab === 'activity' }"
                (click)="selectedTab = 'activity'"
              >
                <a>Activité</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <!-- Budget -->
        <div *ngIf="selectedTab === 'budget'">
          <div
            class="has-v-separation"
            *ngIf="isUserAllowedToAccess('seeBudget')"
          >
            <app-budget-list
              [project]="project"
              [isReadonly]="!isUserAllowedToAccess('manageBudget')"
            ></app-budget-list>
          </div>
        </div>

        <!-- Booked works -->
        <div *ngIf="selectedTab === 'bookedWork'">
          <div *ngIf="isUserAllowedToAccess('seeBookedWorks')">
            <app-booked-work-list
              [project]="project"
              [hasServices]="hasServices"
              [canManageBookedWorks]="
                isUserAllowedToAccess('manageBookedWorks')
              "
              (changed)="reload()"
            ></app-booked-work-list>
          </div>
        </div>

        <!-- Follow up -->
        <div *ngIf="selectedTab === 'followUp'" class="columns">
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-8-fullhd is-offset-2-fullhd"
            *ngIf="isUserAllowedToAccess('seeFollowUp')"
          >
            <app-project-follow-up
              [projectId]="project.id"
            ></app-project-follow-up>
          </div>
        </div>

        <!-- Accounting -->
        <div *ngIf="!project.notBillable && selectedTab === 'accounting'">
          <app-project-accounting
            [project]="project"
            [invoiceToPayId]="invoiceToPay"
            [invoiceToCommentId]="invoiceToComment"
            [canManageAccounting]="isUserAllowedToAccess('manageAccounting')"
            (modalClosed)="invoiceToPay = null"
            *ngIf="isUserAllowedToAccess('seeAccounting')"
          ></app-project-accounting>
        </div>

        <!-- Documents -->
        <div *ngIf="selectedTab === 'documents'">
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-8-fullhd is-offset-2-fullhd"
          >
            <app-document-list [projectId]="project.id"></app-document-list>
          </div>
        </div>

        <!-- Activity -->
        <div *ngIf="selectedTab === 'activity'">
          <app-project-timeline [projectId]="project.id"></app-project-timeline>
        </div>
      </div>
    </div>
    <!-- Chart and totals -->
    <app-project-chart
      [projectId]="project.id"
      *ngIf="isUserAllowedToAccess('seeFullChart')"
    ></app-project-chart>
    <app-project-user-chart
      [projectId]="project.id"
      *ngIf="!isUserAllowedToAccess('seeFullChart')"
    ></app-project-user-chart>
    <div class="card p-a-0 p-b-colgap m-b-gutter full-width">
      <div class="columns is-multiline p-x-gutter p-b-gutter p-t-colgap">
        <div
          class="column is-12-mobile i6-3 mobile i3-desktop-tablet"
          *ngIf="project.customer"
        >
          <p class="title--small">Partner grand compte</p>
          <p>
            <a
              class="project-user"
              [routerLink]="[
                '/collaborateurs',
                project.customer.accountManager.id
              ]"
            >
              <app-image
                [path]="project.customer.accountManager.image"
                [title]="project.customer.accountManager.name"
                [alt]="project.customer.accountManager.name"
                className="avatar-sm"
              >
              </app-image>
              <div>
                <span class="has-text-black">
                  {{
                    project.customer
                      ? project.customer.accountManager.name
                      : '-'
                  }}
                </span>
              </div>
            </a>
          </p>
        </div>
        <div class="column is-12-mobile is-6-tablet is-3-desktop">
          <p class="title--small">Partner mission</p>

          <p>
            <a
              class="project-user"
              [routerLink]="['/collaborateurs', project.workSupervisor.id]"
            >
              <app-image
                [path]="project.workSupervisor.image"
                [title]="project.workSupervisor.name"
                [alt]="project.workSupervisor.name"
                className="avatar-sm"
              >
              </app-image>
              <div>
                <span class="has-text-black">
                  {{ project.workSupervisor.name }}
                </span>
              </div>
            </a>
          </p>
        </div>
        <div class="column is-12-mobile is-6-tablet is-3-desktop">
          <p class="title--small">Responsable opérationnel</p>
          <p>
            <a
              class="project-user"
              [routerLink]="['/collaborateurs', project.projectManager.id]"
            >
              <app-image
                [path]="project.projectManager.image"
                [title]="project.projectManager.name"
                [alt]="project.projectManager.name"
                className="avatar-sm"
              >
              </app-image>
              <div>
                <span class="has-text-black">
                  {{ project.projectManager.name }}
                </span>
              </div>
            </a>
          </p>
        </div>
        <div
          class="column is-12-mobile is-6-tablet is-3-desktop"
          *ngIf="project.projectLeader"
        >
          <p class="title--small">Chef de projet</p>
          <p>
            <a
              class="project-user"
              [routerLink]="['/collaborateurs', project.projectLeader.id]"
            >
              <app-image
                [path]="project.projectLeader.image"
                [title]="project.projectLeader.name"
                [alt]="project.projectLeader.name"
                className="avatar-sm"
              >
              </app-image>
              <div>
                <span class="has-text-black">
                  {{ project.projectLeader.name }}
                </span>
              </div>
            </a>
          </p>
        </div>
        <div class="column is-12" *ngIf="isUserAllowedToAccess('seeFollowUp')">
          <p class="title--small">Collaborateurs</p>
          <div class="columns is-multiline">
            <div class="column is-3" *ngFor="let bookedUser of bookedUsers">
              <a
                class="project-user"
                [routerLink]="['/collaborateurs', bookedUser.id]"
              >
                <app-image
                  [path]="bookedUser.image"
                  [title]="bookedUser.name"
                  [alt]="bookedUser.name"
                  className="avatar-sm"
                >
                </app-image>
                <div>
                  <span class="has-text-black">
                    {{ bookedUser.name }}
                  </span>
                  <span>{{ bookedUser.position.name }}</span>
                </div>
              </a>
            </div>
            <div class="column" *ngIf="bookedUsers && !bookedUsers.length">
              Aucun utilisateur réservé à ce jour.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-project-framework-contract-modal
    *ngIf="showFrameworkContractModal"
    (closedModal)="showFrameworkContractModal = false"
  ></app-project-framework-contract-modal>

  <app-project-validation-modal
    [project]="project"
    [budgets]="budgets"
    [bookedWorks]="bookedWorks"
    *ngIf="
      showValidationModal &&
      project.status === ProjectStatus.PendingValidation &&
      budgets &&
      bookedWorks
    "
    (closed)="onValidationModalClosed($event)"
  ></app-project-validation-modal>

  <app-project-status-edit-modal
    *ngIf="showStatusEditModal"
    [item]="project"
    [fieldSpecialRules]="[
      {
        fieldId: 'projectId',
        hidden: true,
        forcedValue: { projectId: project.id }
      }
    ]"
    (submitSuccessful)="
      showStatusEditModal = false;
      showProjectFinishedModal = $event === ProjectStatus.Finished;
      reload()
    "
    (closedModal)="showStatusEditModal = false"
  ></app-project-status-edit-modal>

  <app-project-finished-modal
    *ngIf="showProjectFinishedModal"
    (closedModal)="showProjectFinishedModal = false"
  ></app-project-finished-modal>
</ng-container>

import { HttpErrorResponse } from '@angular/common/http'
import { Component, Input, OnChanges } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import { Router } from '@angular/router'
import * as moment from 'moment'

import { InputType } from '../../../common/enums/input-type.enum'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { EurosPipe } from '../../../common/pipes/euros.pipe'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Expense } from '../../expense/expense.interface'
import { Project } from '../../project/project.interface'
import { InvoicingZone } from '../invoicing-zone.enum'

@Component({
  selector: 'app-invoice-of-expenses-form',
  templateUrl: './invoice-of-expenses-form.component.html',
  styleUrls: ['./invoice-of-expenses-form.component.scss']
})
export class InvoiceOfExpensesFormComponent implements OnChanges {
  @Input() projectId: number
  @Input() expenses: Expense[]

  form: UntypedFormGroup

  requiredValidators: ValidatorFn[] = [Validators.required]
  showErrors = false
  expensesSelectOptions: SelectOption[]
  amount: number

  InputType = InputType
  InvoicingZone = InvoicingZone

  constructor(
    private formBuilder: UntypedFormBuilder,
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService,
    private router: Router,
    private eurosPipe: EurosPipe
  ) {}

  async ngOnChanges() {
    this.expensesSelectOptions = this.expenses.map((e: Expense) => ({
      label: `${e.name} (${this.eurosPipe.transform(e.amount)})`,
      value: e.id.toString()
    }))

    this.form = this.formBuilder.group({
      billingAddress: [null, Validators.required],
      expenseIds: [
        this.expenses.map((e) => e.id.toString()),
        Validators.minLength(1)
      ],
      issueDate: [moment().format('YYYY-MM-DD'), Validators.required],
      invoicingZone: [InvoicingZone.France, Validators.required],
      reference: ['', Validators.required],
      projectId: [null, Validators.required]
    })

    const project: Project = await this.resourceService
      .show('projects', this.projectId)
      .then((res) => res)

    this.form.patchValue({
      projectId: project.id,
      billingAddress: project.customer.billingAddress
    })

    this.calculateAmount()

    this.form.valueChanges.subscribe(() => {
      this.calculateAmount()
    })
  }

  calculateAmount() {
    this.amount = this.expenses
      .filter(
        (e) =>
          this.form.value.expenseIds &&
          this.form.value.expenseIds.includes(e.id.toString())
      )
      .reduce((sum, curr) => sum + curr.amount, 0)
  }

  submit() {
    if (this.form.invalid) {
      this.showErrors = true

      return this.flashMessageService.error(
        `Impossible d'envoyer le formulaire: certains champs n'ont pas été remplis correctement.`
      )
    }

    this.resourceService
      .store(`projects/${this.projectId}/invoices`, this.form.value)
      .subscribe(
        () => {
          this.flashMessageService.success('La facture a été créée avec succès')
          this.router.navigate(['/missions', this.projectId], {
            queryParams: { selectedTab: 'accounting' }
          })
        },
        (err: HttpErrorResponse) => {
          this.flashMessageService.error(err.error.message)
        }
      )
  }
}

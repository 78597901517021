import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import { ChangeEvent } from '@ckeditor/ckeditor5-angular'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import { AbcInput } from '../../../../common/interfaces/abc-input.interface'

@Component({
  selector: 'abc-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss']
})
export class RichTextInputComponent implements AbcInput, OnChanges {
  @Input() label: string
  @Input() initialValue: { value: string }
  @Input() placeholder: string
  @Input() helpText: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<{ value: string }> = new EventEmitter()

  editor = ClassicEditor
  editorConfig: any
  form: UntypedFormGroup
  required: boolean

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.editorConfig = {
      toolbar: [
        'bold',
        'italic',
        'link',
        'Underline',
        '|',
        'bulletedList',
        'numberedList'
      ],
      placeholder: this.placeholder
    }
    this.form = this.formBuilder.group({
      richText: [
        this.initialValue ? this.initialValue.value : '',
        this.validators || []
      ]
    })
    this.required = this.validators.includes(Validators.required)
  }

  onChange(event: ChangeEvent) {
    const value: string = event.editor.data.get()

    this.form.get('richText').setValue(value)
    this.valueChanged.emit({ value })
  }
}

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { BookedWorkReportComponent } from './analytics/booked-work-report/booked-work-report.component'
import { BusinessReviewComponent } from './analytics/business-review/business-review.component'
import { CutOffComponent } from './analytics/cut-off/cut-off.component'
import { ProjectProfitabilityComponent } from './analytics/project-profitability/project-profitability.component'
import { UserAvailabilityComponent } from './analytics/user-availability/user-availability.component'
import { AuthGuard } from './common/guards/auth.guard'
import { PermissionGuard } from './common/guards/permission.guard'
import { ProjectAbacGuard } from './common/guards/project-abac.guard'
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component'
import { Error404Component } from './pages/error404/error404.component'
import { HomeComponent } from './pages/home/home.component'
import { CreditCreateEditComponent } from './resources/credit/credit-create-edit/credit-create-edit.component'
import { CreditListComponent } from './resources/credit/credit-list/credit-list.component'
import { CustomerCreateEditComponent } from './resources/customer/customer-create-edit/customer-create-edit.component'
import { CustomerDetailComponent } from './resources/customer/customer-detail/customer-detail.component'
import { CustomerListComponent } from './resources/customer/customer-list/customer-list.component'
import { DayOffCreateEditComponent } from './resources/day-off/day-off-create-edit/day-off-create-edit.component'
import { DayOffListComponent } from './resources/day-off/day-off-list/day-off-list.component'
import { DeliverableTypeCreateEditComponent } from './resources/deliverable-type/deliverable-type-create-edit/deliverable-type-create-edit.component'
import { DeliverableTypeListComponent } from './resources/deliverable-type/deliverable-type-list/deliverable-type-list.component'
import { DepartmentCreateEditComponent } from './resources/department/department-create-edit/department-create-edit.component'
import { DepartmentListComponent } from './resources/department/department-list/department-list.component'
import { ExpenseCreateEditComponent } from './resources/expense/expense-create-edit/expense-create-edit.component'
import { InvoiceCreateComponent } from './resources/invoice/invoice-create/invoice-create.component'
import { InvoiceListComponent } from './resources/invoice/invoice-list/invoice-list.component'
import { TemporaryInvoiceEditComponent } from './resources/invoice/temporary-invoice-edit/temporary-invoice-edit.component'
import { ObjectiveCreateEditComponent } from './resources/objective/objective-create-edit/objective-create-edit.component'
import { ObjectiveListComponent } from './resources/objective/objective-list/objective-list.component'
import { PositionCreateEditComponent } from './resources/position/position-create-edit/position-create-edit.component'
import { PositionListComponent } from './resources/position/position-list/position-list.component'
import { ProjectCreateEditComponent } from './resources/project/project-create-edit/project-create-edit.component'
import { ProjectDetailComponent } from './resources/project/project-detail/project-detail.component'
import { ProjectListComponent } from './resources/project/project-list/project-list.component'
import { ReferentCreateEditComponent } from './resources/referent/referent-create-edit/referent-create-edit.component'
import { ReferentListComponent } from './resources/referent/referent-list/referent-list.component'
import { RoleCreateEditComponent } from './resources/role/role-create-edit/role-create-edit.component'
import { RoleListComponent } from './resources/role/role-list/role-list.component'
import { ServiceTypeCreateEditComponent } from './resources/service-type/service-type-create-edit/service-type-create-edit.component'
import { ServiceTypeListComponent } from './resources/service-type/service-type-list/service-type-list.component'
import { TimeSheetListComponent } from './resources/time-sheet/time-sheet-list/time-sheet-list.component'
import { UserBonusesListComponent } from './resources/user/user-bonuses-list/user-bonuses-list.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { UserDetailComponent } from './resources/user/user-detail/user-detail.component'
import { UserListComponent } from './resources/user/user-list/user-list.component'

const routes: Routes = [
  // Auth.
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },

  // Pages.
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },

  // Users.
  {
    path: 'collaborateurs',
    component: UserListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permission: 'browseUsers' }
  },
  {
    path: 'collaborateurs/create',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addUsers',
      mode: 'create'
    }
  },
  {
    path: 'collaborateurs/myself',
    component: UserDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readUsers', 'readOwnUsers'],
      isMyself: true
    }
  },
  {
    path: 'collaborateurs/:id',
    component: UserDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readUsers', 'readOwnUsers']
    }
  },
  {
    path: 'collaborateurs/myself/edit',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: 'edit',
      permission: 'readOwnUsers',
      editMyself: true
    }
  },
  {
    path: 'collaborateurs/:id/edit',
    component: UserCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: 'edit',
      permission: 'editUsers'
    }
  },
  {
    path: 'primes',
    component: UserBonusesListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: { permission: 'browseProjects' }
  },
  // Positions.
  {
    path: 'profils',
    component: PositionListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browsePositions'
    }
  },
  {
    path: 'profils/:create',
    component: PositionCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addPositions',
      mode: 'create'
    }
  },
  {
    path: 'profils/:id/edit',
    component: PositionCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editPositions',
      mode: 'edit'
    }
  },
  // Departments.
  {
    path: 'practices',
    component: DepartmentListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseDepartments'
    }
  },
  {
    path: 'practices/:create',
    component: DepartmentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addDepartments',
      mode: 'create'
    }
  },
  {
    path: 'practices/:id/edit',
    component: DepartmentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editDepartments',
      mode: 'edit'
    }
  },
  // Objectives.
  {
    path: 'objectifs',
    component: ObjectiveListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseObjectives'
    }
  },
  {
    path: 'objectifs/:create',
    component: ObjectiveCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addObjectives',
      mode: 'create'
    }
  },
  {
    path: 'objectifs/:id/edit',
    component: ObjectiveCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editObjectives',
      mode: 'edit'
    }
  },
  // Customers.
  {
    path: 'clients',
    component: CustomerListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseCustomers'
    }
  },
  {
    path: 'clients/create',
    component: CustomerCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addCustomers',
      mode: 'create'
    }
  },
  {
    path: 'clients/:id/edit',
    component: CustomerCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editCustomers',
      mode: 'edit'
    }
  },
  {
    path: 'clients/:id',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'readCustomers'
    }
  },
  // Referents.
  {
    path: 'responsables',
    component: ReferentListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseReferents'
    }
  },
  {
    path: 'responsables/create',
    component: ReferentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addReferents',
      mode: 'create'
    }
  },
  {
    path: 'responsables/:id/edit',
    component: ReferentCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editReferents',
      mode: 'edit'
    }
  },

  // Projects.
  {
    path: 'missions',
    component: ProjectListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseProjects', 'browseOwnProjects']
    }
  },
  {
    path: 'missions/create',
    component: ProjectCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addProjects',
      mode: 'create'
    }
  },
  {
    path: 'missions/:id/edit',
    component: ProjectCreateEditComponent,
    canActivate: [AuthGuard, ProjectAbacGuard],
    data: {
      mode: 'edit',
      policy: 'editProject'
    }
  },
  {
    path: 'missions/:id',
    component: ProjectDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readProjects', 'readOwnProjects']
    }
  },
  // Project resources.
  {
    path: 'missions/:projectId/factures/create',
    component: InvoiceCreateComponent,
    canActivate: [AuthGuard, ProjectAbacGuard],
    data: {
      mode: 'create',
      projectRestricted: true,
      policy: 'manageAccounting',
      projectIdParamName: 'projectId'
    }
  },
  {
    path: 'missions/:projectId/frais/create',
    component: ExpenseCreateEditComponent,
    canActivate: [AuthGuard, ProjectAbacGuard],
    data: {
      mode: 'create',
      projectRestricted: true,
      policy: 'manageAccounting',
      projectIdParamName: 'projectId'
    }
  },
  {
    path: 'missions/:projectId/avoirs/create',
    component: CreditCreateEditComponent,
    canActivate: [AuthGuard, ProjectAbacGuard],
    data: {
      mode: 'create',
      projectRestricted: true,
      policy: 'manageAccounting',
      projectIdParamName: 'projectId'
    }
  },
  {
    path: 'missions/:projectId/frais/:id/edit',
    component: ExpenseCreateEditComponent,
    canActivate: [AuthGuard, ProjectAbacGuard],
    data: {
      mode: 'edit',
      projectRestricted: true,
      policy: 'manageAccounting',
      projectIdParamName: 'projectId'
    }
  },
  {
    path: 'missions/:projectId/factures/:id/edit',
    component: TemporaryInvoiceEditComponent,
    canActivate: [AuthGuard, ProjectAbacGuard],
    data: {
      mode: 'edit',
      projectRestricted: true,
      policy: 'manageAccounting',
      projectIdParamName: 'projectId'
    }
  },
  // Roles.
  {
    path: 'roles',
    component: RoleListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseRoles'
    }
  },
  {
    path: 'roles/create',
    component: RoleCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addRoles',
      mode: 'create'
    }
  },
  {
    path: 'roles/:id/edit',
    component: RoleCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editRoles',
      mode: 'edit'
    }
  },

  // ServiceTypes.
  {
    path: 'types-de-prestation',
    component: ServiceTypeListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseServiceTypes'
    }
  },
  {
    path: 'types-de-prestation/create',
    component: ServiceTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addServiceTypes',
      mode: 'create'
    }
  },
  {
    path: 'types-de-prestation/:id/edit',
    component: ServiceTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editServiceTypes',
      mode: 'edit'
    }
  },

  // Deliverable Types.
  {
    path: 'livrables',
    component: DeliverableTypeListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseDeliverableTypes'
    }
  },
  {
    path: 'livrables/create',
    component: DeliverableTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addDeliverableTypes',
      mode: 'create'
    }
  },
  {
    path: 'livrables/:id/edit',
    component: DeliverableTypeCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editDeliverableTypes',
      mode: 'edit'
    }
  },

  // Days. off
  {
    path: 'jours-off',
    component: DayOffListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editDaysOff'
    }
  },
  {
    path: 'jours-off/create',
    component: DayOffCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addDaysOff',
      mode: 'create'
    }
  },
  {
    path: 'jours-off/:id/edit',
    component: DayOffCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editDaysOff',
      mode: 'edit'
    }
  },

  // TimeSheets.
  {
    path: 'time-sheets',
    component: TimeSheetListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseTimeSheets'
    }
  },
  {
    path: 'mes-time-sheets',
    component: TimeSheetListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      ownResourcesOnly: true,
      permission: 'browseOwnTimeSheets'
    }
  },
  // Expenses.
  {
    path: 'frais/create',
    component: ExpenseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addExpenses',
      mode: 'create'
    }
  },
  {
    path: 'frais/:id/edit',
    component: ExpenseCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editExpenses',
      mode: 'edit'
    }
  },
  // Invoices.
  {
    path: 'factures',
    component: InvoiceListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseInvoices'
    }
  },
  {
    path: 'factures/create',
    component: InvoiceCreateComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addInvoices'
    }
  },
  {
    path: 'factures/:id/edit',
    component: TemporaryInvoiceEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'editInvoices',
      mode: 'edit'
    }
  },
  // Credits.
  {
    path: 'avoirs',
    component: CreditListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseCredits'
    }
  },
  {
    path: 'avoirs/create',
    component: CreditCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'addCredits',
      mode: 'create'
    }
  },

  // Analytics.
  {
    path: 'analytics/rapport-suivi-missions',
    component: ProjectProfitabilityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics/rapport-planning-individuels',
    component: UserAvailabilityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics/rapport-reservations',
    component: BookedWorkReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics/business-review',
    component: BusinessReviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics/cut-off',
    component: CutOffComponent,
    canActivate: [AuthGuard]
  },

  // Demo: Activate only for testing
  // {
  //   path: 'demo/reports',
  //   component: ReportsComponent,
  //   canActivate: [AuthGuard, PermissionGuard],
  //   data: {
  //     permission: 'addUsers', // High level permission.
  //     mode: 'create'
  //   }
  // },

  // 404.
  {
    path: '404',
    component: Error404Component,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

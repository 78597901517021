import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { invoiceDefinition } from '../invoice.definition'

@Component({
  selector: 'app-invoice-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class InvoiceListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = invoiceDefinition

  filters: Filter[] = [
    {
      label: 'Recherche de facture',
      placeholder: 'N° de facture, mission ou client...',

      properties: {
        projectIds: 'projectIds',
        invoiceIds: 'invoiceIds',
        customerIds: 'customerIds'
      },
      searchResources: ['projects', 'invoices', 'customers'],
      inputType: InputType.MultiSearch,
      className: 'is-12-touch is-12-desktop is-6-widescreen p-x-0-mobile'
    },
    {
      label: `Date de facturation`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      placeholder: 'Date de facturation...',
      inputType: InputType.DateRange,
      className: 'is-12-mobile is-12-tablet is-6-widescreen p-x-0-mobile '
    },
    {
      label: `Date de réglement`,
      properties: {
        dateFrom: 'paymentDateFrom',
        dateTo: 'paymentDateTo'
      },
      placeholder: 'Date de réglement...',
      inputType: InputType.DateRange,
      className: 'is-12-mobile is-12-tablet is-6-widescreen p-x-0-mobile '
    },
    {
      label: 'Etat de la facturation',
      placeholder: 'Toutes les factures',
      properties: {
        value: 'validationStatus'
      },
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Factures en project',
          value: 'notValidated'
        },
        {
          label: 'Factures émises',
          value: 'validated'
        }
      ],
      className: 'is-12-mobile is-6-tablet is-6-widescreen p-x-0-mobile '
    },
    {
      label: 'Paiement',
      placeholder: 'Toutes les factures',
      properties: {
        value: 'paymentStatus'
      },
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Non réglées',
          value: 'notPayedOnly'
        },
        {
          label: 'Réglées',
          value: 'payedOnly'
        }
      ],
      className: 'is-12-mobile is-6-tablet is-4-widescreen p-x-0-mobile '
    },
    {
      label: 'Factures en retard',
      properties: {
        value: 'lateOnly'
      },
      inputType: InputType.Checkbox,
      className:
        'is-12-mobile is-6-touch is-4-widescreen is no-label p-x-0-mobile'
    },
    {
      label: 'Missions signées ou terminées uniquement',
      properties: {
        value: 'signedOrFinishedProjectsOnly'
      },
      inputType: InputType.Checkbox,
      className:
        'is-12-mobile is-6-touch is-4-widescreen  no-label p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}

import * as moment from 'moment'

import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { TimeSheet } from './time-sheet.interface'

export const timeSheetDefinition: ResourceDefinition = {
  title: 'Timesheets',
  nameSingular: 'timesheet',
  namePlural: 'timesheets',
  gender: Gender.Masculine,
  slug: 'time-sheets',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.EXPORT],
  defaultLink: null,
  dropdownLinks: [
    {
      type: LinkType.DELETE,
      permission: 'deleteTimeSheets'
    }
  ],
  yields: [
    {
      label: 'Date',
      property: 'date',
      className: 'has-text-link has-icon-edit',
      type: YieldType.Date,
      link: (timeSheet: TimeSheet) =>
        `collaborateurs/${
          timeSheet.user.id
        }?selectedTab=timeSheets&viewDate=${moment(timeSheet.date).format(
          'MM-YYYY'
        )}&timeSheetToEdit=${timeSheet.id}&redirectTo=/time-sheets`
    },
    {
      label: 'Durée (h)',
      property: 'hoursOfWork',
      orderByProperty: 'daysOfWork'
    },
    {
      label: 'Collaborateur',
      property: 'user.imageObjects',
      secondProperty: 'user.name',
      orderByProperty: 'user.name',
      type: YieldType.Image,
      link: (timeSheet: TimeSheet) => `collaborateurs/${timeSheet.user.id}`
    },
    {
      label: 'Valeur',
      property: 'value',
      type: YieldType.Currency,
      disableOrderBy: true
    },
    {
      label: 'Mission',
      property: 'project.name',
      link: (tS: TimeSheet) => (tS.project ? `missions/${tS.project.id}` : null)
    },
    {
      label: 'Client',
      disableOrderBy: true,
      property: 'project.customer.name',
      link: (tS: TimeSheet) =>
        tS.project && tS.project.customer
          ? `clients/${tS.project.customer.id}`
          : null
    },
    {
      label: 'Tâche',
      property: 'reference'
    },
    {
      label: 'Practice',
      property: 'project.practice.color',
      orderByProperty: 'practice.name',
      className: 'has-mini-circle',
      secondProperty: 'project.practice.name',
      type: YieldType.Color
    },
    {
      label: 'Livrables',
      property: 'deliverableDeliverableTypeNames',
      disableOrderBy: true
    }
  ],
  keyNumbers: [
    {
      label: 'Total TS non billable',
      className: 'is-success',
      type: YieldType.Currency,
      extraParams: {
        calculate: 'valueOfBillableTimeSheets'
      }
    },
    {
      label: 'Total TS billable',
      className: 'is-link',
      type: YieldType.Currency,
      extraParams: {
        calculate: 'valueOfNonBillableTimeSheets'
      }
    },
    {
      label: 'Total TS',
      className: 'is-link',
      type: YieldType.Currency,
      extraParams: {
        calculate: 'value'
      }
    }
  ],
  isExportQueued: true
}

import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import moment from 'moment'
import { combineLatest } from 'rxjs'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Expense } from '../../expense/expense.interface'
import { invoiceDefinition } from '../invoice.definition'
import { Invoice } from '../invoice.interface'
import { InvoicingZone } from '../invoicing-zone.enum'

@Component({
  selector: 'app-temporary-invoice-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class TemporaryInvoiceEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = invoiceDefinition

  fields: Field[] = [
    {
      label: 'Libellé',
      properties: {
        value: 'reference'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-3 no-label p-x-0-mobile'
    },
    {
      id: 'expenses',
      label: 'frais concernés',
      properties: {
        value: 'expenseIds'
      },
      inputType: InputType.MultiSelect,
      validators: [],
      hidden: true
    },
    {
      id: 'amount',
      label: `Montant à facturer (HT)`,
      properties: {
        value: 'amount'
      },
      placeholder: `Montant à facturer...`,
      inputType: InputType.Number,
      min: 1,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: `Date d'émission'`,
      placeholder: `Date d'émission'...`,
      properties: {
        value: 'issueDate'
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile',
      onChange: (newValue: { value: string }) => {
        // Set dueDate based on issueDate and customer payment period.
        const dueDateField: Field = this.getFieldById('dueDate')
        const dueDate: string = moment(newValue.value, 'YYYY-MM-DD')
          .add(this.item.project.customer.paymentPeriod, 'days')
          .format('YYYY-MM-DD')

        this.setFieldValue(dueDateField, { value: dueDate })
      }
    },
    {
      id: 'dueDate',
      label: `Date d'échéance`,
      placeholder: `Date d'échéance...`,
      properties: {
        value: 'dueDate'
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: `Zone de facturation`,
      properties: {
        value: 'invoicingZone'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile',
      selectOptions: [
        { value: InvoicingZone.France, label: 'France' },
        { value: InvoicingZone.EU, label: 'Union Européene' },
        { value: InvoicingZone.NonEU, label: 'Export' }
      ]
    },
    {
      label: 'Adresse de facturation',
      placeholder: `Adresse de facturation`,
      properties: {
        value: 'billingAddress'
      },
      inputType: InputType.Textarea,
      validators: [Validators.required],
      className: 'is-4 p-x-0-mobile'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    combineLatest([
      this.componentActivatedRoute.data,
      this.componentActivatedRoute.params
    ]).subscribe({
      next: async ([data, params]) => {
        if (data.projectRestricted) {
          this.definition.slug = `projects/${params.projectId}/invoices`
          this.redirectTo = `/missions/${params.projectId}?selectedTab=accounting`
        }

        await this.initCreateEditView()

        const invoice: Invoice = this.item

        // If Invoice has expenses, we lock amount and display a multi select input with all expenses.
        if (invoice.expenses && invoice.expenses.length) {
          const amountField: Field = this.getFieldById('amount')
          let expensesField: Field = this.getFieldById('expenses')

          amountField.readonly = true

          // Update form value.
          this.form
            .get('expenseIds')
            .setValue(invoice.expenses.map((e) => e.id.toString()))

          // Update field and available options.
          expensesField = Object.assign(expensesField, {
            hidden: false,
            initialValue: {
              value: invoice.expenses.map((e) => e.id.toString())
            },
            validators: [Validators.required, Validators.minLength(1)],
            selectOptions: invoice.project.expenses
              .filter(
                (e) =>
                  e.billableToCustomer &&
                  (!e.invoice || e.invoice.id === invoice.id)
              )
              .map((e) => ({
                label: e.name,
                value: e.id.toString()
              })),
            onChange: (newValue: { value: string[] }) => {
              const selectedExpenses: Expense[] =
                invoice.project.expenses.filter((e) =>
                  newValue.value.includes(e.id.toString())
                )
              amountField.initialValue = {
                value: selectedExpenses.reduce(
                  (sum: number, e: Expense) => sum + e.amount,
                  0
                )
              }
            }
          })
        }
      }
    })
  }
}

import { HttpErrorResponse } from '@angular/common/http'
import { Component, Input, OnChanges } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import { Router } from '@angular/router'
import * as moment from 'moment'

import { InputType } from '../../../common/enums/input-type.enum'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Budget } from '../../budget/budget.interface'
import { Project } from '../../project/project.interface'
import { Invoice } from '../invoice.interface'
import { InvoicingZone } from '../invoicing-zone.enum'
import { Periodicity } from '../periodicity.enum'
import { PreviewInvoice } from '../preview-invoice.interface'

@Component({
  selector: 'app-invoice-plan-form',
  templateUrl: './invoice-plan-form.component.html',
  styleUrls: ['./invoice-plan-form.component.scss']
})
export class InvoicePlanFormComponent implements OnChanges {
  @Input() budget: Budget
  @Input() projectId: number
  @Input() redirectTo?: string

  form: UntypedFormGroup
  InputType = InputType
  periodicities: SelectOption[] = Object.keys(Periodicity).map((key) => ({
    label: Periodicity[key],
    value: Periodicity[key]
  }))
  requiredValidators: ValidatorFn[] = [Validators.required]
  previewInvoices: PreviewInvoice[]
  initialAmount: number
  isStartAndEndPeriodicity = false
  showErrors = false
  loadingSubmit = false

  invoiceCountValidators: ValidatorFn[] = [
    Validators.required,
    Validators.min(1),
    Validators.pattern(/^[a-zA-Z0-9]+$/)
  ]
  amountValidators: ValidatorFn[]
  InvoicingZone = InvoicingZone

  constructor(
    private formBuilder: UntypedFormBuilder,
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService,
    private router: Router
  ) {}

  ngOnChanges() {
    this.amountValidators = this.budget
      ? [
          Validators.required,
          Validators.max(Number(this.budget.remainingAmount.toFixed(2)))
        ]
      : this.requiredValidators

    this.initialAmount = this.budget
      ? Number(this.budget.remainingAmount.toFixed(2))
      : 0

    this.form = this.formBuilder.group({
      billingAddress: [null, Validators.required],
      amount: [this.initialAmount, this.amountValidators],
      periodicity: [Periodicity.None, Validators.required],
      invoiceCount: [1, this.invoiceCountValidators],
      dateFrom: [moment().format('YYYY-MM-DD'), Validators.required],
      invoicingZone: [InvoicingZone.France, Validators.required],
      reference: [this.budget ? this.budget.name : '', Validators.required]
    })

    this.form.valueChanges.subscribe((formValues) => {
      this.getPreviewInvoices()
    })

    this.getPreviewInvoices()

    this.resourceService
      .show('projects', this.projectId)
      .then((project: Project) => {
        this.form
          .get('billingAddress')
          .setValue(project.customer.billingAddress)
      })
  }

  getPreviewInvoices() {
    this.previewInvoices = []

    if (!this.form.value.invoiceCount) {
      return
    }

    const invoiceAmounts: number[] = this.getInvoiceAmounts(
      this.form.value.amount,
      this.form.value.invoiceCount
    )

    const periodicity: Periodicity = this.form.value.periodicity
    let dateFrom: moment.Moment = moment(this.form.value.dateFrom)

    for (let i = 0; i < this.form.value.invoiceCount; i++) {
      this.previewInvoices.push({
        amount: invoiceAmounts[i],
        issueDate: dateFrom.format('YYYY-MM-DD'),
        budgetId: this.budget ? this.budget.id : null,
        projectId: this.projectId,
        invoicingZone: this.form.value.invoicingZone
      })

      if (periodicity === Periodicity.Monthly) {
        dateFrom.add(1, 'month')
      } else if (periodicity === Periodicity.Bimonthly) {
        dateFrom.add(2, 'months')
      } else if (periodicity === Periodicity.Biannual) {
        dateFrom.add(6, 'months')
      } else if (periodicity === Periodicity.Annual) {
        dateFrom.add(1, 'year')
      } else if (periodicity === Periodicity.StartAndEnd) {
        dateFrom = moment(this.budget.project.estimatedEndDate)
      }
    }
  }

  // Calculate all invoice amounts taking care of eventual rounding issues.
  private getInvoiceAmounts(
    totalAmount: number,
    invoiceCount: number
  ): number[] {
    const individualAmount: number =
      Math.floor((totalAmount / invoiceCount) * 100) / 100

    const remainingAmountToBill: number =
      totalAmount - invoiceCount * individualAmount

    return Array(invoiceCount)
      .fill(individualAmount)
      .map((amount: number, i: number) => {
        if (i === invoiceCount - 1) {
          return amount + remainingAmountToBill
        }
        return amount
      })
  }

  patchForm(newValueObj: { [key: string]: any }) {
    // If change periodicity to StartAndEnd we apply special rules.
    if (
      JSON.stringify(newValueObj) ===
        JSON.stringify({ periodicity: Periodicity.StartAndEnd }) ||
      (this.form.value.periodicity === Periodicity.StartAndEnd &&
        Object.keys(newValueObj)[0] !== 'periodicity')
    ) {
      this.isStartAndEndPeriodicity = true
      Object.assign(newValueObj, {
        invoiceCount: 2,
        dateFrom: this.budget.project.estimatedStartDate
      })
    } else {
      this.isStartAndEndPeriodicity = false
    }

    this.form.patchValue(newValueObj)
  }

  async submit() {
    if (this.form.invalid) {
      this.showErrors = true

      return this.flashMessageService.error(
        `Impossible d'envoyer le formulaire: certains champs n'ont pas été remplis correctement.`
      )
    }

    this.loadingSubmit = true

    // If we don't have budget (free invoice), we create one before creating invoices.
    if (!this.budget) {
      this.budget = await this.resourceService
        .store(`projects/${this.projectId}/budgets`, {
          name: this.form.value.reference,
          projectId: this.projectId,
          managementFeesPercentage: 0,
          withService: this.form.value.reference,
          withServiceAmount: this.previewInvoices.reduce(
            (sum: number, curr: PreviewInvoice) => sum + curr.amount,
            0
          )
        })
        .toPromise()
        .then((budgetRes: Budget) => budgetRes)
        .catch((err) => {
          this.loadingSubmit = false
          this.flashMessageService.error(
            `Impossible de créer le budget: ${err.message}`
          )
          return null
        })
    }

    // Patch common data to all invoices.
    this.previewInvoices.forEach((previewInvoice: PreviewInvoice) => {
      previewInvoice.budgetId = this.budget.id
      previewInvoice.billingAddress = this.form.value.billingAddress
      previewInvoice.reference = this.form.value.reference
    })

    const storePromises: Promise<Invoice>[] = []
    this.previewInvoices.forEach((previewInvoice: PreviewInvoice) => {
      storePromises.push(
        this.resourceService
          .store(`projects/${this.projectId}/invoices`, previewInvoice)
          .toPromise()
      )
    })

    Promise.all(storePromises).then(
      () => {
        this.loadingSubmit = false
        this.flashMessageService.success(
          storePromises.length > 1
            ? `Les ${storePromises.length} factures ont été créées avec succès.`
            : 'La facture a été créée avec succès'
        )

        if (this.redirectTo) {
          this.router.navigateByUrl(this.redirectTo)
        } else {
          this.router.navigate(['/factures'])
        }
      },
      (err: HttpErrorResponse) => {
        this.loadingSubmit = false
        this.flashMessageService.error(err.error.message)
      }
    )
  }
}

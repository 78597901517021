<!-- Form-->
<form [formGroup]="form">
  <div class="columns flex flex-wrap">
    <div
      class="column is-12-mobile is-6-tablet is-6-desktop p-x-0-mobile"
      *ngIf="!budget"
    >
      <abc-input
        [type]="InputType.Text"
        label="Libellé"
        (valueChanged)="patchForm({ reference: $event.value })"
        [validators]="requiredValidators"
        [showErrors]="showErrors"
      ></abc-input>
    </div>
    <div class="column is-12-mobile is-6-tablet is-6-desktop p-x-0-mobile">
      <abc-input
        [type]="InputType.Textarea"
        [initialValue]="{ value: form.value.billingAddress }"
        [validators]="requiredValidators"
        label="Adresse de facturation"
        placeholder="Adresse de facturation..."
        [showErrors]="showErrors"
        (valueChanged)="patchForm({ billingAddress: $event.value })"
        *ngIf="form.value.billingAddress !== null"
      ></abc-input>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-2-fullhd p-x-0-mobile no-label"
    >
      <abc-input
        [type]="InputType.Select"
        [initialValue]="{ value: form.value.invoicingZone }"
        [validators]="requiredValidators"
        placeholder="Sélectionner la zone de facturation..."
        label="Zone de facturation"
        [selectOptions]="[
          { value: InvoicingZone.France, label: 'France' },
          { value: InvoicingZone.EU, label: 'Union Européene' },
          { value: InvoicingZone.NonEU, label: 'Export' }
        ]"
        (valueChanged)="patchForm({ invoicingZone: $event.value })"
        [showErrors]="showErrors"
      ></abc-input>
    </div>
  </div>
  <div class="columns">
    <div class="column m-t-colgap">
      <h3 class="title--small has-text-black">Plan de facturation</h3>
    </div>
  </div>
  <div class="columns">
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
    >
      <abc-input
        [type]="InputType.Number"
        [initialValue]="{ value: form.value.amount }"
        label="Montant à facturer (HT)"
        [min]="0"
        [max]="budget ? budget.remainingAmount : null"
        [step]="0.01"
        (valueChanged)="patchForm({ amount: $event.value })"
        [validators]="amountValidators"
        [showErrors]="showErrors"
        *ngIf="amountValidators"
      ></abc-input>
      <!-- Budget invoicing quick shortcuts -->
      <ng-container>
        <p class="help" *ngIf="budget">
          Vous facturez
          {{ form.value.amount / initialAmount | percent : '1.0-2' }} du montant
          restant du budget.
          <a
            *ngIf="form.value.amount === initialAmount"
            (click)="patchForm({ amount: initialAmount / 2 })"
            >Facturer 50%</a
          >
          <a
            *ngIf="form.value.amount !== initialAmount"
            (click)="patchForm({ amount: initialAmount })"
            >Facturer 100%</a
          >
        </p>
        <p></p>
      </ng-container>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
    >
      <abc-input
        [type]="InputType.Select"
        label="Periodicité"
        placeholder="Choisissez la periodicité des factures"
        [selectOptions]="periodicities"
        [initialValue]="{ value: form.value.periodicity }"
        (valueChanged)="patchForm({ periodicity: $event.value })"
        [validators]="requiredValidators"
        [showErrors]="showErrors"
      >
      </abc-input>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
      *ngIf="!isStartAndEndPeriodicity"
    >
      <abc-input
        [type]="InputType.Number"
        label="Nombre de factures"
        [min]="0"
        [initialValue]="{ value: form.value.invoiceCount }"
        (valueChanged)="patchForm({ invoiceCount: $event.value })"
        [validators]="invoiceCountValidators"
        [showErrors]="showErrors"
      ></abc-input>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
      *ngIf="!isStartAndEndPeriodicity"
    >
      <abc-input
        [type]="InputType.Datepicker"
        label="Date 1ère facture"
        [initialValue]="{ value: form.value.dateFrom }"
        (valueChanged)="patchForm({ dateFrom: $event.value })"
        [validators]="requiredValidators"
        [showErrors]="showErrors"
      ></abc-input>
    </div>
  </div>
</form>

<!-- Preview -->
<div class="card no-shadow m-y-gutter">
  <div class="columns">
    <div class="column">
      <h3 class="title--small has-text-black">
        Prévisualisation des factures générées
      </h3>
    </div>
  </div>
  <div
    class="columns flex flex-mobile invoice-list"
    *ngFor="let previewInvoice of previewInvoices; let i = index"
  >
    <div class="column is-4-mobile is-4-tablet">
      <div class="flex flex-mobile align-center flex-start">
        <i class="icon m-r-md icon-file-text is-size-4 is-hidden-mobile"></i>
        <span>Facture {{ i + 1 }}</span>
      </div>
    </div>
    <div class="column is-4-mobile is-4-tablet is-3-widescreen p-x-0-mobile">
      {{ previewInvoice.issueDate | date }}
    </div>
    <div
      class="column is-4-mobile is-4-tablet is-3-widescreen has-text-right p-l-0-mobile"
    >
      {{ previewInvoice.amount | euros }}
    </div>
  </div>
</div>
<div class="columns" *ngIf="previewInvoices">
  <div class="column is-12 has-text-right">
    <a
      (click)="submit()"
      class="button is-primary"
      [ngClass]="{ 'is-loading': loadingSubmit }"
      >Enregistrer</a
    >
  </div>
</div>

export const environment = {
  envName: 'production',
  isAltVersion: false,
  production: true,
  baseUrl: 'https://zele.nextep-health.com',
  apiBaseUrl: 'https://api.zele.nextep-health.com',
  storagePath: 'https://api.zele.nextep-health.com/storage',
  tokenName: 'zeleToken',
  tokenWhitelistedDomains: ['api.zele.nextep-health.com'],
  enableBugsnag: true,
  bugsnagApiKey: 'd11732012ca18ec602ffb0e1823d6aa7'
}
